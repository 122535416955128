<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-primary has-text-weight-bold is-size-4">
        {{ $t('Change Dashboard Layout') }}
      </h3>
    </div>
    <div class="column is-12">
      <p class="m-b-2x">
        {{ $t('Select widget you want to see') }}
      </p>
      <a-checkbox-group v-model:value="localValue">
        <div class="columns is-mini-gap is-multiline">
          <div v-for="widget in widgets" :key="widget.id" class="column is-4">
            <a-checkbox :value="widget.id">
              {{ widget.title }}
            </a-checkbox>
          </div>
        </div>
      </a-checkbox-group>
    </div>
    <div class="column is-12">
      <div class="form-title">
        {{ $t('Drag to rearrange') }}
      </div>
    </div>
    <div class="column is-12">
      <draggable v-model="localValue" class="sort-wrapper">
        <template #item="{ element }">
          <div class="sort-box" :class="`size-${getWidget(element).size}`">
            <div class="sort-box-inner">
              {{ getWidget(element).title }}
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        :disabled="loading"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="loading"
        :size="size"
        style="min-width: 115px"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import { getDefaultWidgets, getWidgetsByIds } from '@/views/home/components/widgets';

export default {
  name: 'WidgetsConfig',
  components: { draggable },
  emits: ['close', 'cancel'],
  data() {
    return {
      localValue: [],
      size: 'large',
      loading: false,
    };
  },
  computed: {
    ...mapState('user', { permissions: 'permissions', value: 'widgets', roles: 'roles' }),
    widgets() {
      return getDefaultWidgets(this.permissions, this.roles);
    },
    selectedWidgets() {
      return getWidgetsByIds(this.localValue, this.permissions, this.roles);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.localValue = [...getWidgetsByIds(nv, this.permissions, this.roles).map((w) => w.id)];
        }
      },
    },
  },
  methods: {
    getWidget(id) {
      return this.selectedWidgets.find((w) => w.id === id);
    },
    async handleSubmit() {
      try {
        this.loading = true;
        await this.$store.dispatch('user/updateWidgets', this.localValue);
        this.$emit('close');
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    resetFields() {
      this.localValue = [...this.value];
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
:deep .ant-checkbox-group {
  width: 100%;

  .ant-checkbox-wrapper {
    height: 1.2rem;
    font-size: 11px;
    overflow: hidden;
  }
}

.sort-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.sort-box {
  padding: 5px;
  flex: 0 0 50%;

  &.size-1 {
    flex: 0 0 25%;
  }

  &.size-2 {
    flex: 0 0 50%;
  }

  &.size-3 {
    flex: 0 0 75%;
  }

  &.size-4 {
    flex: 0 0 100%;
  }
}

.sort-box-inner {
  border: 1px solid #888888;
  padding: 5px;
  line-height: 1.1;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  overflow: hidden;
}
</style>
