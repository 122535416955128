<template>
  <div v-if="selectedWidgets" class="widgets">
    <Widget v-for="w in selectedWidgets" :key="w.id" :value="w" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getWidgetsByIds } from '@/views/home/components/widgets';
import Widget from '@/views/home/components/Widget';

export default {
  name: 'Widgets',
  components: { Widget },
  computed: {
    ...mapState('user', { permissions: 'permissions', value: 'widgets', roles: 'roles' }),
    selectedWidgets() {
      return getWidgetsByIds(this.value, this.permissions, this.roles);
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  @include mobile {
    display: block;
  }
}
</style>
