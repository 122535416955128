<!-- eslint-disable -->
<template>
  <div class="dashboard-page page">
    <div class="is-mini section">
      <div class="container">
        <div class="has-text-right m-b-4x">
          <ModalWide>
            <template #default="{ hide }">
              <WidgetsConfig @close="hide" @cancel="hide" />
            </template>
            <template #handler="{ show }">
              <a class="is-size-7" @click="show">
                <span class="m-r">{{ $t('Change Dashboard Layout') }}</span>
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style="vertical-align: middle"
                >
                  <path
                    d="M15.75 9.625V7L19.25 10.5L15.75 14V11.375H11.375V15.75H14L10.5 19.25L7 15.75H9.625V11.375H5.25V14L1.75 10.5L5.25 7V9.625H9.625V5.25H7L10.5 1.75L14 5.25H11.375V9.625H15.75Z"
                    fill="#00596C"
                  />
                </svg>
              </a>
            </template>
          </ModalWide>
        </div>
        <Widgets />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalWide from '@/components/ModalWide';
import WidgetsConfig from '@/views/home/components/WidgetsConfig';
import Widgets from '@/views/home/components/WidgetsView';

export default {
  name: 'Home',
  components: { Widgets, WidgetsConfig, ModalWide },
  computed: {
    ...mapGetters({
      hideSimulationPage: 'hideSimulationPage',
    }),
  },
  created() {
    this.$store.dispatch('user/getWidgets');

    if (!this.$messaging) {
      return;
    }

    this.$messaging.getToken({ vapidKey: process.env.VUE_APP_WEB_PUSH_KEY }).then((token) => {
      if (token) {
        // if user is not admin, save token device
        if (this.$store.getters.roles.indexOf('ADMIN') < 0) {
          const payload = { userId: this.$store.getters.uid, fcmToken: token };
          this.$store.dispatch('user/addDevice', payload);
        }
        this.$messaging.onMessage((payload) => {
          const notification = new Notification(payload.data.title, {
            body: payload.data.body || '',
          });
          notification.onclick = (e) => {
            e.preventDefault();
            notification.close();
            window.open(payload.data.url);
          };
        });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.boxed-link {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: $card-border;
  padding: 8 * $bleed 4 * $bleed;
  border-radius: $radius;

  span {
    font-size: $size-7;
  }
}

.dashboard-page {
  min-height: calc(100vh - 73px);
  background-color: #f5f5f5;
}
</style>
