<template>
  <div class="widget" :class="`size-${value.size}`">
    <div class="widget-inner">
      <div
        v-if="value.type !== 'summary'"
        class="has-text-primary has-text-weight-bold is-size-4 widget-header"
      >
        <router-link :to="value.url">
          {{ value.title }}
        </router-link>
      </div>
      <div class="widget-content" :class="`widget-content-${value.type}`">
        <component
          :is="dataComponent"
          :data="data"
          :loading="loading"
          :config="value"
          @change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import LoadingContainer from '@/components/LoadingContainer';

export default {
  name: 'Widget',
  components: {
    LoadingContainer,
    DataTable: defineAsyncComponent(() => import(/* webpackChunkName: "dataTable" */ '@/views/home/components/DataTable')),
    DataSummary: defineAsyncComponent(() => import(/* webpackChunkName: "dataSummary" */ '@/views/home/components/DataSummary')),
    DataChart: defineAsyncComponent(() => import(/* webpackChunkName: "dataChart" */ '@/views/home/components/DataChart')),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      data: undefined,
    };
  },
  computed: {
    dataComponent() {
      switch (this.value.type) {
        case 'table':
          return 'DataTable';
        case 'summary':
          return 'DataSummary';
        case 'chart':
          return 'DataChart';
        default:
          return 'DataTable';
      }
    },
  },
  mounted() {
    const payload = {
      ...(this.value.type === 'table' ? { pageSize: 3 } : undefined),
      ...this.value.payload,
    };
    this.fetchData(payload);
  },
  methods: {
    async fetchData(payload) {
      try {
        this.loading = true;
        this.data = await this.$store.dispatch(this.value.action, payload);
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 10px;
  flex: 0 0 50%;

  &.size-1 {
    flex: 0 0 25%;
  }

  &.size-2 {
    flex: 0 0 50%;
  }

  &.size-3 {
    flex: 0 0 75%;
  }

  &.size-4 {
    flex: 0 0 100%;
  }
}

.widget-inner {
  border: 1px solid #dcdcdc;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  background-color: #ffffff;
}

.widget-header {
  line-height: 1.1;
  padding-bottom: 10px;
}

.widget-content {
  height: 250px;
  overflow-y: auto;

  &.widget-content-summary {
    height: 286px;
  }

  @include mobile {
    height: auto;
    min-height: 250px;
    &.widget-content-summary {
      height: auto;
      min-height: 286px;
    }
  }
}

:deep .ant-empty {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
